import React, { forwardRef, useState } from 'react';
import { Field, renderCheckbox, renderTextField, renderSelectField } from '../../Common/FormFieldsHooks';
import { useMutation, useQuery } from '@apollo/client';
import { resetDrawerDetails } from '../../../reactiveVariables';
import {
  DELETE_CONFIGURATION,
  INSERT_CONFIGURATION,
  UPDATE_CONFIGURATION,
  GET_INITIATIVE_STATE_CONFIG,
  UPDATE_CONFIGURATION_MANY,
} from '../../../containers/Settings/GraphQL/configuration';
import { UPDATE_INITIATIVE_MANY } from '../../../containers/ValueStream/Initiatives/graphql';
import ElementDetails from '../../Common/ElementDetail';
import { MenuItem } from '@mui/material';
import { GET_ALL_INITIATIVES } from '../../../containers/ValueStream/Initiatives/graphql';

const InitiativeStatesDetails = ({ element, canEdit, additionalData }, ref) => {
  const [insertIntiativeState] = useMutation(INSERT_CONFIGURATION);
  const [updateIntiativeState] = useMutation(UPDATE_CONFIGURATION);
  const [deleteIntiativeState] = useMutation(DELETE_CONFIGURATION);
  const [updateInitiativeMany] = useMutation(UPDATE_INITIATIVE_MANY);
  const [updateConfigurationsMany] = useMutation(UPDATE_CONFIGURATION_MANY);

  const { data: { initiatives = [] } = {}, refetch: refetchInitiatives } = useQuery(GET_ALL_INITIATIVES);
  const { data: { initiativeStates = [] } = {} } = useQuery(GET_INITIATIVE_STATE_CONFIG);

  const [error, setError] = useState(false);

  const valueStreamInitativeStates = initiativeStates.filter(
    (state) => state.value_stream === additionalData?.valueStream?.id,
  );

  const onDelete = () => {
    deleteIntiativeState({
      variables: { id: element.id },
      refetchQueries: [GET_INITIATIVE_STATE_CONFIG, 'GetInitiativeState'],
    }).then(() => {
      const remainingStates = initiativeStates.filter(
        (state) => state.value_stream === additionalData?.valueStream?.id && state.id !== element.id,
      );

      const reorderedStates = remainingStates.map((state, index) => ({
        id: state.id,
        rank: index + 1,
      }));

      const updates = reorderedStates.map((state) => ({
        where: { id: { _eq: state.id } },
        _set: { rank: state.rank },
      }));

      if (updates.length) {
        updateConfigurationsMany({
          variables: { updates },
          refetchQueries: [GET_INITIATIVE_STATE_CONFIG, 'GetInitiativeState'],
        }).then(() => {
          resetDrawerDetails();
          refetchInitiatives();
        });
      }
    });
  };

  const onSubmit = (values) => {
    // Check for existing names, excluding the current element if it's an edit
    const nameExists = valueStreamInitativeStates.some((state) => state.name === values.name && state.id !== values.id);

    if (nameExists) {
      setError(true);
      return;
    }

    setError(false);

    const dataToSave = {
      name: values.name,
      inUse: !!values.inUse,
      stateType: values.stateType,
    };

    const mutation = values.id ? updateIntiativeState : insertIntiativeState;

    const variables = values.id
      ? { configuration: { value: dataToSave }, configurationId: values.id }
      : {
          configuration: {
            type: 'initiativeState',
            value: dataToSave,
            value_stream: additionalData?.valueStream?.id,
            rank: valueStreamInitativeStates.length + 1,
          },
        };

    mutation({
      variables,
      refetchQueries: [GET_INITIATIVE_STATE_CONFIG, 'GetInitiativeState'],
    }).then(() => {
      resetDrawerDetails();
      if (values.id) {
        const initiativeForValueStream = initiatives.filter(
          (initiative) =>
            initiative.valueStream === additionalData?.valueStream?.id &&
            initiative.initiative_state_details.name === element.name,
        );

        const updates = initiativeForValueStream.map((item) => ({
          where: { id: { _eq: item.id } },
          _set: {
            initiative_state_details: {
              id: values.id,
              name: values.name,
              stateType: values.stateType,
            },
          },
        }));

        if (updates.length) {
          updateInitiativeMany({
            variables: { updates },
          }).then(() => {
            refetchInitiatives();
          });
        }
      }
    });
  };

  const stateTypes = [{ name: 'ToDo' }, { name: 'Waiting' }, { name: 'Active' }, { name: 'Done' }];

  return (
    <ElementDetails
      ref={ref}
      element={element}
      defaultValues={element}
      canEdit={canEdit}
      submitFunc={onSubmit}
      deleteFunc={onDelete}
      title="Initiative States">
      <Field name="name" renderField={renderTextField} label="Name" size={12} required error={error} />
      {error && <p style={{ color: '#df6161' }}>An initiative state with this name already exists</p>}
      <Field name="stateType" renderField={renderSelectField} label="State type" required>
        {stateTypes.map((element) => (
          <MenuItem key={element.id} value={element}>
            {element.name}
          </MenuItem>
        ))}
      </Field>
      <Field name="inUse" renderField={renderCheckbox} label="In use" size={15} />
    </ElementDetails>
  );
};

export default forwardRef(InitiativeStatesDetails);
