import React, { useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { Text } from '@mantine/core';
import { sortBy } from 'lodash';

import TimeLineDashboard from './TimeLineDashboard';
import { getColumnDates, getPredictabilityForPeriod, getPreviousPeriod } from './utils';
import ValueStreamMetrics from './ValueStreamMetrics';
import { selectedProductThemeVar, selectedTeamVar, selectedOwnerVar } from '../../../reactiveVariables';
import { filterProductTheme } from '../../../utils/helpers';
import { useProjectedFeatures } from '../../../utils/hooks';
import { GET_FEATURES_FOR_VS } from '../../Product/Features/graphql';
import { GET_PROGRAM_INCREMENTS, GET_SPRINTS } from '../../Settings/ProgramIncrement/graphql';
import { GET_INITIATIVES } from '../Initiatives/graphql';
import { GET_MILESTONES_WITH_FEATURES } from '../Milestones/graphql';
import { initiativeWithDates } from '../utils';
import { SegmentedControl, Group } from '@mantine/core';

const InitiativesDashboard = () => {
  const { data: { initiatives = [] } = {} } = useQuery(GET_INITIATIVES);
  const { data: { milestones = [] } = {} } = useQuery(GET_MILESTONES_WITH_FEATURES);
  const { data: { sprints = [] } = {}, loading } = useQuery(GET_SPRINTS);
  const { data: { features = [] } = {} } = useQuery(GET_FEATURES_FOR_VS);
  const { data: { programIncrements = [] } = {} } = useQuery(GET_PROGRAM_INCREMENTS);

  const [dateType, setDateType] = useState('quarter');
  const [columDates, setColumnDates] = useState(getColumnDates(dateType));
  const projectedFeatures = useProjectedFeatures();

  const selectedProductTheme = useReactiveVar(selectedProductThemeVar);
  const selectedTeams = useReactiveVar(selectedTeamVar);
  const selectedOwners = useReactiveVar(selectedOwnerVar);

  const predictability = useMemo(
    () => getPredictabilityForPeriod(features, columDates, programIncrements),
    [features, columDates, programIncrements],
  );
  const previousPredictability = useMemo(
    () => getPredictabilityForPeriod(features, getPreviousPeriod(dateType, columDates), programIncrements),
    [features, columDates, programIncrements],
  );

  const onDateTypeChange = (value) => {
    setColumnDates(getColumnDates(value));
    setDateType(value);
  };

  // Existing useMemo for initiativesWithDates with adjusted logic, refactor later methinks!
  const initiativesWithDates = useMemo(
    () =>
      sortBy(
        initiatives
          .filter((initiative) => filterProductTheme(selectedProductTheme, initiative))
          .map((initiative) => initiativeWithDates(initiative, sprints, projectedFeatures)),
        'rank',
      ),
    [selectedProductTheme, initiatives, sprints, projectedFeatures], // Ensuring all relevant dependencies are listed..
  );

  const filterInitiativesByTeams = (initiatives, selectedTeams) => {
    // If selectedTeams is an empty array, return all initiatives
    if (selectedTeams.length === 0) {
      return initiatives;
    }

    return initiatives
      .map((initiative) => {
        const hasMatchingTeam =
          Array.isArray(initiative.teams) &&
          initiative.teams.some((team) => {
            return selectedTeams.includes(team.id);
          });

        if (!hasMatchingTeam) {
          return null;
        }

        const filteredFeatures = initiative.features.nodes.filter((feature) => selectedTeams.includes(feature.teamId));

        return {
          ...initiative,
          features: {
            ...initiative.features,
            nodes: filteredFeatures,
          },
        };
      })
      .filter((initiative) => initiative !== null);
  };

  const filterInitiativesByOwners = (initiatives, selectedOwners) => {
    if (selectedOwners.length === 0) {
      return initiatives;
    }

    return initiatives
      .map((initiative) => {
        const hasMatchingOwner = selectedOwners.includes(initiative.owner);

        // Filter features by assignedTo field
        const filteredFeatures = initiative.features.nodes.filter((feature) => {
          return selectedOwners.includes(feature.assignedTo);
        });

        // Return the initiative if it has a matching owner or matching features
        if (hasMatchingOwner || filteredFeatures.length > 0) {
          return {
            ...initiative,
            features: {
              ...initiative.features,
              nodes: filteredFeatures, // Return only the filtered features
            },
          };
        }

        return null;
      })
      .filter((initiative) => initiative !== null);
  };

  const [initiativesToggle, setInitiativesToggle] = useState('allInitiatives');

  const handleSwitch = (value) => {
    setInitiativesToggle(value);
  };

  const TOGGLE_DONE = [
    { value: 'allInitiatives', label: 'All Initiatives' },
    { value: 'excludeDone', label: 'Exclude Done' },
  ];

  const filteredTeamInitiatives = filterInitiativesByTeams(initiativesWithDates, selectedTeams);
  const filteredInitiatives = filterInitiativesByOwners(filteredTeamInitiatives, selectedOwners);
  const filteredInitiativesByToggle =
    initiativesToggle === 'allInitiatives'
      ? filteredInitiatives
      : filteredInitiatives.filter(
          (initiative) => initiative.initiative_state_type !== 'Done' && initiative.portfolio_state !== 'Done',
        );

  return (
    <div>
      <ValueStreamMetrics
        previousPredictability={previousPredictability}
        predictability={predictability}
        milestones={milestones}
        initiatives={initiativesWithDates}
        dateType={dateType}
        setDateType={onDateTypeChange}
      />
      <Group position="apart" align="center" mb={16}>
        <Text size="lg" fw={700}>
          Initiatives Breakdown
        </Text>
        <SegmentedControl value={initiativesToggle} onChange={handleSwitch} data={TOGGLE_DONE} size="sm" />
      </Group>
      <TimeLineDashboard
        initiatives={filteredInitiativesByToggle}
        projectedFeatures={projectedFeatures}
        columDates={columDates}
        loading={loading}
        setColumnDates={setColumnDates}
        dateType={dateType}
      />
    </div>
  );
};

export default InitiativesDashboard;
